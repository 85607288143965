/**
 * 画面遷移してきた時のアニメーション時の背景の表示
 *      ・[class="bg"]に[data-mode="1"]が付いていた時に動く
 *      ・[class="bg"]配下の[class="js-animation"]が対象
 *
 *      ・以下のdata属性で挙動を操作できる
 *          - data-aniamtion-index      処理される順番（昇順）
 *          - data-animation-type       処理タイプ
 *          - data-animation-delay      処理開始までの時間
 *          - data-animation-duration   次のアニメーションまでの時間
 */
$(function() {
    // loadで初期化処理を動かす
    let isInit = false;
    $(window).on('load', init);

    // macosのsafariでloadが動かないことがあるらしいので、
    // 0.5秒たっても処理が動いていなければ処理を起動するようにした
    setTimeout(() => {
        if (!isInit) {
            console.log('loadの不具合');
            init();
        }
    }, 1000);

    function init() {
        if (isInit) {
            // すでに実行済みなら終了
            return false;
        }

        isInit = true;

        let $loader = $('.loader-wrapper');
        $loader.fadeOut(500);

        // モード定義
        enum mode {
            animation = 1,      // アニメーションをする
            notAniamtion = 2,   // アニメーションをしない
        }

        // オプション名
        enum options {
            index = 'animation-index',
            type = 'animation-type',
            delay = 'animation-delay',
            duration = 'animation-duration',
        }

        // アニメーションタイプ
        enum types {
            removeClass = 'removeClass',
        }

        const BEFORE_CLASS_NAME = 'before';
        const ACTIVE_CLASS_NAME = 'active';
        const ANIME_CLASS_NAME = 'animation';

        let $body = $('body');
        let $bg = $('.bg');
        let $menu = $('.js-animation-menu');
        let $cloud = $('.js-cloud');
        let $main = $('.main-content');
        let $modal = $('.modal-content');
        let $jump = $('.js-jump');

        /**
         * 画面遷移する時のアニメーション時の背景の非表示
         *      a[class="animation-link"]のリンクをクリックした時だけイベント発火
         *
         *      以下が対象
         *          ・[class="js-animation"]
         *          ・[class="js-animation-menu"]
         */
        $(document).on('click', 'a.animation-link', (e) => {
            e.preventDefault();

            $('.lity').hide(); // モーダルが開いてたら閉じる

            let href = $(e.currentTarget).attr('href');

            if (isHrefEqualsCurrent(href)) {
                // 現在地と遷移後の位置が同じ場合にはアニメーションせずに遷移
                location.href = href;
            } else {
                // リファラーが違う場合はアニメーションして移動
                $jump.attr('src', $jump.data('jumpSrc'));
                $('.js-animation, .js-animation-menu, .js-cloud, .js-balloon2, .js-balloon').css('transition', '0.3s').addClass(BEFORE_CLASS_NAME);

                $main.fadeOut(100);
                $modal.fadeOut(100);

                setTimeout(() => {
                    location.href = href;
                }, 600);
            }
        });

        /**
         * リファラーをチェックして、同じ階層からの遷移かどうか確認する
         */
        function isHrefEqualsCurrent(href) {
            if (location.href.indexOf(href) != -1) {
                return true;
            }
            return false
        }

        if (!$body.hasClass(ANIME_CLASS_NAME)) {
            // アニメーションを動かすモードではない場合は処理終了
            animatinoEnd();
        } else {
            // アニメーションを動かすモード
            $cloud.removeClass(BEFORE_CLASS_NAME);

            if (isIE()) {
                // IE11だとうごかないから一気に表示
                let $this = $bg.find(`.js-animation`);

                $this.removeClass(BEFORE_CLASS_NAME);
                setTimeout(() => {
                    let $img = $this.find('img.js-jump');
                    if ($img.length > 0) {
                        $img.attr('src', $img.data('src'));
                    }
                }, 600);

                animatinoEnd();

            } else {
                // アニメーション実行
                animation(1, 0);
            }
        }

        /**
         * 順番にアニメーションを実行
         *      index           対象のdata属性
         *      beforeDuration  全てのアニメーションが終わった時の待ち時間
         */
        async function animation(index, beforeDuration) {
            // 対象を取得
            let $this = $bg.find(`.js-animation[data-${options.index}="${index}"]`);

            if ($this.length === 0) {
                // 対象がなくなればメニューを表示して終了
                setTimeout(() => {
                    animatinoEnd();
                }, beforeDuration);
                return false;
            }

            // オプション取得
            let type = $this.data(options.type) ? $this.data(options.type) : types.removeClass;
            let delay = $this.data(options.delay) ? $this.data(options.delay) : 1000;
            let duration = $this.data(options.duration) ? $this.data(options.duration) : 1000;

            // 実行
            await play($this, type, delay, duration).then(() => {
                setTimeout(() => {
                    let $img = $this.find('img.js-jump');
                    if ($img.length > 0) {
                        $img.attr('src', $img.data('src'));
                    }
                }, duration*2);
                animation(++index, duration)
            });
        }

        /**
         * アニメーション完了後に動かす処理
         */
        function animatinoEnd() {
            $menu.removeClass(BEFORE_CLASS_NAME);
            $(window).trigger('custommodal');
            // $loader.remove();
        }

        /**
         * アニメーション実行
         */
        function play($this, type, delay, duration) {
            return new Promise((resolve) => {
                // アクション
                setTimeout(() => {
                    switch (type) {
                        case types.removeClass:
                            $this.removeClass(BEFORE_CLASS_NAME);
                            break;
                    }
                }, delay);

                // 次のアクションへ
                setTimeout(() => resolve(), duration);
            });
        }

        function isIE() {
            let userAgent = window.navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
                return true;
            }
            return false;
        }
    }
});


// my dataの表示
$(function() {
    const CLASS_NAME = 'active';
    const BEFORE_CLASS_NAME = 'before';

    let $document = $(document);
    let $mydataBtn = $('.js-mydata');
    let $mydataBox = $('.js-mydata-box');

    $mydataBtn.on('click', (e) => {
        e.preventDefault();
        if ($mydataBtn.hasClass(CLASS_NAME)) {
            close();
        } else {
            open();
        }
    });

    $document.on('click', (e) => {
        if ($mydataBtn.hasClass(BEFORE_CLASS_NAME)) {
            if (!$(e.target).closest(`.${CLASS_NAME}`).length) {
                close();
            }
        }
    });

    function open() {
        $mydataBtn.stop().addClass(CLASS_NAME);
        $mydataBox.stop().addClass(CLASS_NAME);
        setTimeout(() => {
            $mydataBtn.css('transition-duration', '0s');
            $mydataBtn.addClass(BEFORE_CLASS_NAME).removeClass(CLASS_NAME);
        }, 500);
    }

    function close() {
        $mydataBtn.css('transition-duration', '1s');
        $mydataBtn.stop().removeClass(BEFORE_CLASS_NAME);
        $mydataBox.stop().removeClass(CLASS_NAME);
    }
});


// バウンド
$(function() {
    const CLASS_NAME = 'a-bounce';
    const ON_DATA_NAME = 'bounceSrc';
    const OFF_DATA_NAME = 'src';

    let $bounce = $('.js-bounce');

    if (!$bounce.length) {
        return false;
    }

    let $bounceImg = $bounce.find('img');

    $bounce.on('click', () => {
        $bounce.addClass(CLASS_NAME);
        $bounceImg.attr('src', $bounceImg.data(ON_DATA_NAME));
        setTimeout(() => {
            $bounce.removeClass(CLASS_NAME);
            $bounceImg.attr('src', $bounceImg.data(OFF_DATA_NAME));
        }, 500);
    });
});


// バルーンが画面からはけたらすぐに出す
$(function() {
    const ACTIVE_CLASS = 'active';

    let $window = $(window);
    let $balloon = $('.birthday_balloon');

    if (!$balloon.length) {
        return false;
    }

    checkBalloon();

    function checkBalloon() {
        if ($window.width() < $balloon.offset().left) {
            $balloon.removeClass(ACTIVE_CLASS);
            setTimeout(() => {
                $balloon.addClass(ACTIVE_CLASS);
            }, 300);
        }
        setTimeout(() => checkBalloon(), 100);
    }
});


// 横禁止
$(function() {

    /**
     * 画面を横にした時の処理
     */
    directionCheck();
    $(window).on('orientationchange', directionCheck);

    /**
     * スマホで回転された時に表示するダイアログ
     */
    function directionCheck() {
        const CLASS_NAME = 'active';
        let $target = createWarn();
        let tmpScreen: any = screen; // tsだとorientationがないというエラーがでてしまうので...
        let angle = (tmpScreen && tmpScreen.orientation && tmpScreen.orientation.angle) || window.orientation || 0;
        if (angle % 180 !== 0) {
            // ヨコ
            $target.addClass(CLASS_NAME);
        } else {
            // タテ
            $target.removeClass(CLASS_NAME);
        }
    }

    /**
     * 横向き注意用の表示を返す
     */
    function createWarn() {
        let targetName = 'sp-side-warn-box';

        // 注意用のタグを取得
        let $target = $('#' + targetName);

        if ($target.length > 0) {
            // 取得できればそれを返す
            return $target;
        }

        // 取得できない場合は作る
        $target = $('<div>');
        $target.attr('id', targetName);
        $target.attr('class', targetName);

        $('body').append($target);
        return $target;
    }
});


// バルーンをクリックした時にpoint計算
$(function() {
    $('.js-balloon').on('click', function() {
        $.ajax({url: '/birthday/add_point_ajax.php'});
    });
});


// アコーディオン
$(function() {
    var $accordion = $('.js-accordion');
    $accordion.aemAccordion({
        callback: function() {
            var $this = $(this);
            $this.parent('div').toggleClass('js-open');
        },
    });
});
